<template>
  <v-dialog v-model="dialog" width="85%" scrollable>
    <v-card class="pa-6" color="white" outlined>
      <v-row class="list">
        <v-card-title>Crear nueva Solicitud de Pedido Compras</v-card-title>
        <v-spacer />
        <v-btn plain @click.native="closeDialog">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-divider class="my-5" />
      <v-row align="center" justify="center">
        <v-col cols="12" md="2">
          <v-text-field v-mask="['XXXX.XXXX']" v-model="spc" :disabled="!oldSPC" readonly prepend-icon="mdi-card-account-details-outline"
            maxlength="12" label="N° SPC" />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field v-model="rut" prepend-icon="mdi-card-account-details-outline" maxlength="10"
            hint="Rut proveedor sin dígito verificador" label="Rut proveedor" @change="getProveedores()" />
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete v-model="nom_prov" :items="proveedores" label="Nombre proveedor" prepend-icon="mdi-account-hard-hat" required @change="getRUT()"/>
        </v-col>
        <v-spacer />
      </v-row>
      <!-- agregar item a SPC -->
      <v-dialog width="75%" v-model="newitem" scrollable>
        <v-card class="pa-6" color="white" outlined>
          <v-row>
            <v-col cols="12" md="2">
              <v-row>
              <v-radio-group class="cost-font" v-model="tipo" @change="setCodigo()">
                <v-radio label="Stock" value="stock" />
                <v-radio label="Servicio" value="servicio" />
                <v-radio label="Obra en curso" value="o_curso" />
              </v-radio-group>
              </v-row>
              <v-row>
              <v-checkbox class="cost-font" v-if="tipo=='stock'" v-model="newcode" label="Crear nuevo código" hint="Seleccionar en caso de que el copdigo no exista" />
              </v-row>
            </v-col>
            <v-col cols="12" md="10">
              <v-row>
                <v-col cols="12" md="3">
                  <span v-if="tipo=='o_curso'">
                    <v-text-field class="cost-font" v-model="codigo" label="Codigo" readonly prepend-inner-icon="mdi-barcode-scan" />
                  </span>
                  <span v-else-if="tipo=='servicio'">
                    <v-select class="cost-font" v-model="codigo" label="Tipo servicio" :items="cod_servicios" prepend-inner-icon="mdi-barcode-scan"
                       @change="finddesc()">
                    </v-select>
                  </span>
                  <span v-else>
                    <v-text-field class="cost-font" v-model="codigo" label="Codigo" :disabled="newcode" prepend-inner-icon="mdi-barcode-scan"
                      @change="finddesc()" />
                  </span>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field class="cost-font" v-model="desc_cod" label="Descripcion" :readonly="!newcode" prepend-inner-icon="mdi-card-text-outline" />
                </v-col>
              </v-row>
              <v-row v-if="tipo=='servicio'">
                <v-col cols="12" md="3">
                <v-select v-model="typeserv" :items="opertype" /></v-col>
                <v-col cols="12" md="3">
                <v-select v-model="typeelement" :items="elementos" /></v-col>
                <v-text-field label="Por favor describa el detalle de la operacion" />
              </v-row>
              <v-row >
                <v-card v-if="typeelement=='motores'" width="100%" outlined flat>
                  <v-card-subtitle>Datos motor</v-card-subtitle>
                  <v-row>
                    <v-col sm="2">
                      <v-text-field v-model="nmotor" label="Numero Motor" />
                    </v-col>
                    <v-col sm="2">
                      <v-text-field v-model="potencia" label="Potencia" />
                    </v-col>
                    <v-col sm="2">
                      <v-switch flat v-model="u_potencia" class="mx-2" :label="`${t_potencia}`"></v-switch>
                    </v-col>
                    <v-col sm="2">
                      <v-text-field v-model="rpm" label="Velocidad" persistent-hint :hint="`Relacion i: ${rel_i}`" />
                    </v-col>
                  </v-row>
                </v-card>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field class="cost-font" v-model="ceco" label="CECO" prepend-inner-icon="mdi-cash"
                    @change="findcecos()" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field class="cost-font" v-model="desc_ceco" label="Nombre CECO"
                    prepend-inner-icon="mdi-card-text-outline" />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field class="cost-font" v-model="oc_" :disabled="!oc" label="Obra en curso"
                    prepend-inner-icon="mdi-cash" @change="cost()" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field class="cost-font" v-model="desc_oc" :disabled="!oc" label="Descripcion" readonly
                    prepend-inner-icon="mdi-card-text-outline" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field class="cost-font" v-model="v_unit" label="Costo unitario" prepend-inner-icon="mdi-cash"
                    @change="cost()" />
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field class="cost-font" v-model="cant" label="Cantidad"
                    prepend-inner-icon="mdi-animation-outline" />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field class="cost-font" v-model="v_total" label="Valor total" readonly
                    prepend-inner-icon="mdi-cash-multiple" @change="cost()" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn plain @click="insert()">agregar item</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-file-input v-model="file" show-size label="Seleccione cotizacion">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" md="3">
          <v-file-input v-model="email" show-size label="Seleccione correo">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" md="3">
          <v-file-input v-model="other" show-size label="Seleccione archivo adicional">
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-spacer />
        <v-col align-self="center" cols="12" md="2">
          <v-btn  v-if="!oldSPC" block @click="nwitem()" plain>Nuevo Item</v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-textarea class="text-uppercase" outlined v-model="comentario" label="Comentarios adicionales" rows="2" />
      </v-row>
      <!-- mostrar items incluidos en SPC -->
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table dense :headers="headers" :items="items" disable-pagination :hide-default-footer="true">
            <template v-if="!oldSPC" v-slot:[`item.actions`]="{ item }">
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-icon :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mr-2"
                  @click="delItem(item)">
                  mdi-delete-variant
                </v-icon>
              </v-hover>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-col align-self="center" cols="12" md="2">
          <v-btn v-if="!oldSPC" block @click="regSPC()" plain>Registrar SPC</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CostDataService from "../../services/CostDataService";
// import { format, parseISO } from 'date-fns'
export default {
  name: "semaDlg",
  data () {
    return {
      // oc:false,
      headers:[
        { text: "Codigo", value: "CODIGO", align:"start", sortable: false },
        { text: "Descripcion", value: "DESC_CODIGO", align:"start", sortable: false },
        { text: "Cantidad", value: "CANTIDAD", align:"center", sortable: false },
        { text: "$ Costo", value: "COSTO", align:"center", sortable: false },
        { text: "CenCo", value: "CECO", align:"center", sortable: false },
        { text: "", value: "actions", align:"center", sortable: false }
      ],
      proveedores:[],
      rut:undefined,
      nom_prov:undefined,
      items:[],
      comentario:undefined,
      file:undefined,
      email:undefined,
      other:undefined,   
      dialog:false,
      tipo:"stock",
      cod_servicios:[
        { text:'Infraestructura',value:'6000066'},
        { text:'Suministros',value:'6000067'},
        { text:'Mantenimiento',value:'6000068'},
        { text:'Operaciones',value:'6000069'}
        ],
      opertype:[
        { text:'Servicio en planta', value:'servicio'},
        { text:'Trabajo externo', value:'reparacion'},
        { text:'Provisionamiento', value:'compra'}
      ],
      typeserv: undefined,
      typeelement: undefined,
      u_potencia: true,
      rpm:'',
      v_unit:undefined,
      cant:undefined,
      ceco:undefined,
      spc:undefined,
      newitem:false,
      codigo:undefined,
      desc_cod:undefined,
      desc_ceco:undefined,
      oc:false, //? obra en curso
      oc_:'', //?numero obra en curso
      desc_oc:'', //?descripcion obra en curso
      oldSPC:false,
      newcode:false
  }},
  computed: {
    v_total: function () {
      let unit = this.v_unit?this.v_unit.replace(/\D/g,''):0
      let cant = this.cant?this.cant.replace(/\D/g,''):0
      let total_cost = unit * cant
      return Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(total_cost)
    },
    elementos: function () {
      if (this.typeserv=='servicio') {
        return [
          { text:'Calibracion', value:'calibracion'},
          { text:'Certificacion', value:'certificacion'},
          { text:'Instalacion', value:'instalacion'},
          { text:'Mantencion', value:'mantencion'},
        ]
      } else if(this.typeserv=='reparacion') {
        return [
          { text:'Fabricacion', value:'fabricacion'},
          { text:'Rectificacion', value:'rectificacion'},
          { text:'Rep. Motores', value:'motores'},
          { text:'Mantencion', value:'mantencion'},
        ]
      } else {
        return [
          { text:'Materiales', value:'materiales'}
        ]
      }
    },
    t_potencia: function () {
      if (this.u_potencia) {
        return 'KW'
      } else {
        return 'HP'
      }
    },
    rel_i: function () {
      let val=this.rpm.toString().split('/')
      console.log(val);
      return (1/(val[1]/val[0])||0).toFixed(2)
    }
    },
  watch: {
    desc_cod: (val) => {
      this.desc_cod = val.toUpperCase()
    }
    },
  methods: {
    open: function (id) {
      this.id = id
      this.getProveedores()
      if (id){
        this.setSPC(id)
        this.oldSPC=true
        this.dialog = true
      }else{
        this.setDefaultSPC()
        this.oldSPC=false
        this.dialog = true
      }
    },
    setSPC (nspc) {
      let data = {}
      data['spc']= nspc
      CostDataService.getSPC(data)
      .then((response) => {
        let item = response.data.spcs[0]
        this.spc = item.N_SPC
        this.rut = item.RUT_PROV
        this.nom_prov = item.DESC_PROV
        this.items = item.ITEMS
      })
    },
    setDefaultSPC(){
      this.rut = undefined
      this.nom_prov = undefined
      this.items = []
      this.comentario = undefined
      this.file = undefined
      this.email = undefined
      this.other = undefined
      return
    },
    setDefaultItem(){
      this.codigo=undefined
      this.desc_cod=undefined
      this.ceco=undefined
      this.desc_ceco=undefined
      this.oc_=undefined
      this.desc_oc=undefined
      this.v_unit=undefined
      this.cant=undefined
      this.v_total=undefined
      return
    },
    findcecos() {
      let data = {}
      data['ceco']= this.ceco
      CostDataService.getCeco(data)
      .then((response) => {
        console.log(response.data)
        this.desc_ceco = response.data.nom_ceco
      })
    },
    closeDialog() {
      this.dialog = false;
    },
    getProveedores(){
      let data = {}
      if (this.rut) {data['rut']= this.rut.replace(/\D/g,'')}
      if (this.nom_prov) {data['prov'] = this.nom_prov}
      CostDataService.getProveedores(data)
      .then((response) =>{
        if (this.rut) {
          this.nom_prov=response.data.nom_prov
        } else if (this.nom_prov) {
          this.rut = response.data.rut
        } else {
        this.proveedores = response.data.map(el => el._id)
      }
      })
      return
    },
    getRUT(){
      let data = {}
      if (this.nom_prov) {data['prov'] = this.nom_prov}
      CostDataService.getProveedores(data)
      .then((response) =>{
          this.rut = response.data.rut
      })
    },
    nwitem(){
      this.setDefaultItem()
      this.newitem=true
    },
    cost (){
      let cost = this.v_unit.replace(/\D/g,'')
      this.v_unit = Intl.NumberFormat("es-CL", {
              style: "currency",
              currency: "CLP"
            }).format(cost)
    },
    setCodigo() {
      if (this.tipo == 'o_curso' ) {
        this.codigo='6000199'
        this.finddesc()
        this.oc=true
      } else {
        this.codigo=''
        this.desc_cod=''
        this.oc=false
      }
    },
    delItem (item) {
      let testIndex = this.items.indexOf(item)
      let newArray = this.items.slice(0, testIndex).concat(this.items.slice(testIndex+1, this.items.length))
      this.items = newArray
    },
    finddesc(){
      let data={}
      data['codigo']=this.codigo
      CostDataService.getDescodigo(data)
      .then((response)=>{
        this.desc_cod = `${response.data.codigo} - ${response.data.descripcion}`
      })
    },
    insert() {
      let data = {}
      data['CODIGO']=this.codigo
      data['DESC_CODIGO']=this.desc_cod
      data['CECO']=this.ceco
      data['DESC_CECO']=this.desc_ceco
      data['OC']=this.oc_
      data['DESC_OC']=this.desc_oc
      data['V_UNIT']=this.v_unit
      data['CANTIDAD']=this.cant
      data['COSTO']=this.v_total
      this.items.push(data)
      this.newitem=false
    },
    regSPC() {
      let data = {}
      data['FECHA_SPC'] = new Date()
      data['EMPRESA'] = '15'
      data['DESC_EMPRESA'] = 'AGROINDUSTRIAL EL PAICO S.A.'
      data['ITEMS'] = this.items
      data['RUT_PROV'] = this.rut
      data['DESC_PROV'] = this.nom_prov
      data['COMENTARIO'] = this.comentario
      CostDataService.newSPC(data)
        .then((response) => {
          let spc_ = response.data.SPC
          if (this.file) {
            CostDataService.upFiles(this.file, spc_)
              .then((response) => {
                console.log(response.data)
                this.file = undefined
              })
          }
          if (this.email) {
            CostDataService.upFiles(this.email, spc_)
              .then((response) => {
                console.log(response.data)
                this.email = undefined
              })
          }
          if (this.other) {
            CostDataService.upFiles(this.other, spc_)
              .then((response) => {
                console.log(response.data)
                this.other = undefined
              })
          }
        })
      this.dialog = false
    },
  },
};
</script>

<style>
.cost-font * {
  font-size: 15px;
}
.edit-form {
  max-width: 1100px;
  margin: auto;
}
</style>